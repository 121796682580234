:root {
    --blue: #1e90ff;
    --rc-white: #ffffff;
    --rc-white-v2: #FBFBFB;
    --rc-bg: #f1ebe4;
    --rc-main: #666;
    --rc-header: #999;
    --rc-black: #000;
    --rc-gray: #3f3f3f;
    --rc-gray-v2: #545454;
    --rc-gray-v3: #E7E7E7;
    --rc-gray-v4: #B2B2B2;
    --rc-gray-v5: #656565;
    --rc-gray-v6: #b6b4b4;
    --rc-gray-v6: #666666;
    --rc-gray-v7: #d9d9d9;
    --rc-gray-v8: #B7B7B7;
    --rc-pink: #DF5656;
    --rc-red: #FF0000;
    --rc-red-v2: #960000;
    --rc-red-v3: #ff4d4f;
    --rc-red-v4: #FC2C2B;
    --rc-red-v5: #DB0000;
    --rc-green: #3CAB24;
    --rc-orange: #FF5F00;

    --rf-main: 13px;
    --rf-header: 11px;
}

@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
    font-family: "Century";
    src: url("../font/century-gothic/FontsFree-Net-GOTHICB0.ttf") format("opentype");
    font-style: normal;
    font-weight: 400;
}

* {
    font-family: 'Noto Sans JP', sans-serif !important;
}

body {
    margin: 0;
    font-family: 'Noto Sans JP', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--rc-bg);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#wrapper {
    margin-bottom: 80px;
    min-height: 100vh;
}
