.menu-fixed {
    z-index: 999;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 80px;
    width: 100%;
    background: #fff;
    .list {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        background-color: var(--vt-c-black-bold);
        box-shadow: var(--vt-box-shadow-2);
        .shop-now {
            position: relative;
            .badge {
                position: absolute;
                font-size: 6px;
                font-weight: 900;
                top: -10px;
                right: 4px;
                background-color: var(--rc-red-v5);
                height: 21px;
                width: 21px;
                justify-content: center;
                align-items: center;
                display: flex;
                color: var(--rc-white);
                border-radius: 50%;
            }
        }
        .menu-icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .icon {
                height: 25px;
                margin-bottom: 3px;
                &.icon-hover {
                    display: none;
                }
            }
            .txt {
                font-size: 10px !important;
                margin: 0;
                font-size: var(--rf-header);
                color: var(--rc-header);
                font-weight: 500;
            }
            &.active {
                .icon {
                    height: 25px;
                    &:not(.icon-hover) {
                        display: none;
                    }
                    &.icon-hover {
                        display: block;
                    }
                }
                .txt {
                    color: var(--rc-black);
                    font-weight: 500;
                }
            }
        }
    }
}

.header-top {
    background: var(--rc-black);
    height: 70px;
    @include center_flex;
    .title {
        color: var(--rc-white);
        text-transform: uppercase;
        font-size: 24px;
        font-weight: 800;
        margin: 0;
    }
    img {
        width: 62.55px;
        height: 42px;
        object-fit: contain;
    }
}
