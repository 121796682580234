.history-list {
    font-weight: 900;
    padding: 32px 12px;

    .item {
        align-items: center;
        display: flex;
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
        .title {
            margin: 0;
            color: var(--rc-gray-v2);
            font-size: 14px;
            font-weight: 700;
        }
        .text-danger {
            color: var(--rc-red);
        }
        .time {
            flex: 1;
            margin-right: 15px;
            border: 0.8px solid var(--rc-main);
            border-radius: 100px;
            height: 17px;
            line-height: 20px;
            font-size: 13px;
            color: var(--rc-main);
            @include center_flex;
            align-items: center;
            .anticon {
                margin-right: 4px;
                font-size: 12px;
            }
        }
        .btn-detail {
            background-color: var(--rc-gray-v2);
            border-radius: 4px;
            height: 19px;
            cursor: pointer;
            @include center_flex;
            font-size: 12px;
            color: var(--rc-white);
            line-height: 1;
            flex: 1;
        }
        .info {
            width: 166px;
        }
        .title-content {
            width: calc(100% - 166px);
        }
        .group-info {
            display: flex;
        }
    }
}
